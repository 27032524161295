




































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Department, Position, isEqual } from '@/models';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';

@Component({
  name: 'PositionSelectInput',
})
export default class extends Vue {
  @VModel({ type: [Position, Object] }) position!: Position | null;
  @Prop({ type: String, default: 'Select a Position' })
  readonly label!: string;

  @Prop({ type: [Department, Object] }) readonly department!: Department | null;
  @Prop({ type: Boolean, default: false }) readonly scoped!: boolean;

  @Prop({ type: Boolean, default: false }) readonly allowAdd!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit!: boolean;
  @Prop({ type: String, default: null }) readonly target!: string;

  items: Position[] = [];

  search = '';

  loading = false;
  editing: Position | null = null;

  searchPositions = debouncedSearch(() => this.fetchPositions());

  get disabled(): boolean {
    return this.department == null;
  }

  async setPosition(position: string | Position): Promise<void> {
    if (position === null) {
      this.$emit('input', null);
      return;
    }

    if (position instanceof Position) {
      this.$emit('input', position);
    }
  }

  async fetchPositions(): Promise<void> {
    this.loading = true;
    if (this.department?.exists() || this.scoped) {
      this.fetchDepartmentPositions();
    } else {
      this.fetchAllPositions();
    }
    this.loading = false;
  }

  async fetchAllPositions(): Promise<void> {
    this.items = await Position.where('name', this.search).get();
  }

  async fetchDepartmentPositions(): Promise<void> {
    if (this.department?.exists()) {
      this.items = await this.department.positions().where('name', this.search).get();
    }
  }

  async create(name: string): Promise<void> {
    if (this.department) {
      const response = await this.department.positions().create({ name });
      this.$emit('input', response);
    }

    await this.fetchDepartmentPositions();
  }

  async edit(index: number, item: Position): Promise<void> {
    if (!this.editing) {
      this.editing = item;
    } else {
      this.editing = null;
      await item.update();
      this.searchPositions();
    }
  }

  @Watch('search')
  async onSearchChanged(): Promise<void> {
    this.searchPositions();
  }

  @Watch('department')
  async onDepartmentChanged(newVal: Department | null, oldVal: Department | null): Promise<void> {
    this.fetchDepartmentPositions();
    if (!isEqual(newVal, oldVal)) {
      this.$emit('input', null);
    }
  }
}
