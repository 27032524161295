




































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Department, Site, isEqual } from '@/models';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';

@Component({
  name: 'DepartmentSelectInput',
})
export default class extends Vue {
  @VModel({ type: [Department, Object] }) department!: Department | null;
  @Prop({ type: String, default: 'Select a Department' })
  readonly label!: string;

  @Prop({ type: [Site, Object] }) readonly site!: Site | null;
  @Prop({ type: Boolean, default: false }) readonly scoped!: boolean;

  @Prop({ type: Boolean, default: false }) readonly allowAdd!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit!: boolean;
  @Prop({ type: String, default: null }) readonly target!: string;

  items: Department[] = [];

  search = '';

  loading = false;
  editing: Department | null = null;

  searchDepartments = debouncedSearch(() => this.fetchDepartments());

  get disabled(): boolean {
    return this.site == null;
  }

  async setDepartment(department: string | Department): Promise<void> {
    department;
    if (!department) {
      return;
    }

    if (department instanceof Department) {
      this.$emit('input', department);
    }
  }

  async fetchDepartments(): Promise<void> {
    this.loading = true;
    if (this.site?.exists() || this.scoped) {
      this.fetchSiteDepartments();
    } else {
      this.fetchAllDepartments();
    }
    this.loading = false;
  }

  async fetchAllDepartments(): Promise<void> {
    this.items = await Department.where('name', this.search).get();
  }

  async fetchSiteDepartments(): Promise<void> {
    if (this.site?.exists()) {
      this.items = await this.site.departments().where('name', this.search).get();
    }
  }

  async create(name: string): Promise<void> {
    if (this.site) {
      const response = await this.site.departments().create({ name });
      this.$emit('input', response);
    }

    await this.fetchSiteDepartments();
  }

  async edit(index: number, item: Department): Promise<void> {
    if (!this.editing) {
      this.editing = item;
    } else {
      this.editing = null;
      await item.update();
      this.searchDepartments();
    }
  }

  @Watch('search')
  async onSearchChanged(): Promise<void> {
    this.searchDepartments();
  }

  @Watch('site')
  async onSiteChanged(newVal: Site | null, oldVal: Site | null): Promise<void> {
    this.fetchSiteDepartments();
    if (!isEqual(newVal, oldVal)) {
      this.$emit('input', null);
    }
  }
}
