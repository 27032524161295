




































































import Component from 'vue-class-component';
import Vue from 'vue';
import { Department, Shift, isEqual } from '@/models';
import { Prop, VModel, Watch } from 'vue-property-decorator';
import { debouncedSearch } from '@/utils/inputSearch';

@Component({
  name: 'ShiftSelectInput',
})
export default class extends Vue {
  @VModel({ type: [Shift, Object] }) shift!: Shift | null;
  @Prop({ type: String, default: 'Select a Shift' })
  readonly label!: string;

  @Prop({ type: [Department, Object] }) readonly department!: Department | null;
  @Prop({ type: Boolean, default: false }) readonly scoped!: boolean;

  @Prop({ type: Boolean, default: false }) readonly allowAdd!: boolean;
  @Prop({ type: Boolean, default: false }) readonly allowEdit!: boolean;
  @Prop({ type: String, default: null }) readonly target!: string;

  items: Shift[] = [];

  search = '';

  loading = false;
  editing: Shift | null = null;

  searchShifts = debouncedSearch(() => this.fetchShifts());

  get disabled(): boolean {
    return this.department == null;
  }

  async setShift(shift: string | Shift): Promise<void> {
    if (shift === null) {
      this.$emit('input', null);
      return;
    }

    if (shift instanceof Shift) {
      this.$emit('input', shift);
    } else {
      await this.create(shift);
    }
  }

  async fetchShifts(): Promise<void> {
    this.loading = true;
    if (this.department?.exists() || this.scoped) {
      this.fetchDepartmentShifts();
    }
    this.loading = false;
  }

  async fetchAllShifts(): Promise<void> {
    this.items = await Shift.where('name', this.search).get();
  }

  async fetchDepartmentShifts(): Promise<void> {
    if (this.department?.exists()) {
      this.items = await this.department.shifts().where('name', this.search).get();
    }
  }

  async create(name: string): Promise<void> {
    if (this.department) {
      const response = await this.department.shifts().create({ name });
      this.$emit('input', response);
    }

    await this.fetchDepartmentShifts();
  }

  async edit(index: number, item: Shift): Promise<void> {
    if (!this.editing) {
      this.editing = item;
    } else {
      this.editing = null;
      await item.update();
      this.searchShifts();
    }
  }

  @Watch('search')
  async onSearchChanged(): Promise<void> {
    this.searchShifts();
  }

  @Watch('department')
  async onDepartmentChanged(newVal: Department | null, oldVal: Department | null): Promise<void> {
    this.fetchDepartmentShifts();
    if (!isEqual(newVal, oldVal)) {
      this.$emit('input', null);
    }
  }
}
